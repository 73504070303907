import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0ad7dcfa = () => interopDefault(import('../pages/asbis-in-your-country.vue' /* webpackChunkName: "pages/asbis-in-your-country" */))
const _3aaebc12 = () => interopDefault(import('../pages/vendors/index.vue' /* webpackChunkName: "pages/vendors/index" */))
const _f064584a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _137869c6 = () => interopDefault(import('../pages/catalog/_slug.vue' /* webpackChunkName: "pages/catalog/_slug" */))
const _3484743f = () => interopDefault(import('../pages/documents/_slug/index.vue' /* webpackChunkName: "pages/documents/_slug/index" */))
const _577a3215 = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))
const _ec85a48a = () => interopDefault(import('../pages/solution/_slug.vue' /* webpackChunkName: "pages/solution/_slug" */))
const _3e1bfea2 = () => interopDefault(import('../pages/vendors/_slug.vue' /* webpackChunkName: "pages/vendors/_slug" */))
const _67f9a35c = () => interopDefault(import('../pages/where-to-buy/_slug.vue' /* webpackChunkName: "pages/where-to-buy/_slug" */))
const _f3d19ada = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/asbis-in-your-country",
    component: _0ad7dcfa,
    name: "asbis-in-your-country___ro"
  }, {
    path: "/vendors",
    component: _3aaebc12,
    name: "vendors___ro"
  }, {
    path: "/",
    component: _f064584a,
    name: "index___ro"
  }, {
    path: "/catalog/:slug?",
    component: _137869c6,
    name: "catalog-slug___ro"
  }, {
    path: "/documents/:slug",
    component: _3484743f,
    name: "documents-slug___ro"
  }, {
    path: "/news/:slug?",
    component: _577a3215,
    name: "news-slug___ro"
  }, {
    path: "/solution/:slug?",
    component: _ec85a48a,
    name: "solution-slug___ro"
  }, {
    path: "/vendors/:slug",
    component: _3e1bfea2,
    name: "vendors-slug___ro"
  }, {
    path: "/where-to-buy/:slug?",
    component: _67f9a35c,
    name: "where-to-buy-slug___ro"
  }, {
    path: "/:slug",
    component: _f3d19ada,
    name: "slug___ro"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
